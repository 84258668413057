import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';
import packageInfo from '../package.json';


if (environment.production) {
  enableProdMode();
}


if (['dev', 'production'].includes(environment.NAME)) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: environment.NAME,
    release: 'v' + packageInfo.version,

    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
