import { createReducer, on } from '@ngrx/store';
import * as LinkedAccountActions from './linked-account.actions';
import { LinkedAccount, LinkedAccountEvent } from '@shared/meta-data';

export interface LinkedAccountState {
  isLoading: boolean;
  isSyncComplete: boolean;
  linkedAccounts: LinkedAccount[] | null;
  linkedAccountEvents: LinkedAccountEvent[] | null;
  newEventsWhereProcessed: boolean;
  linkedAccountsWithEventMap: Map<LinkedAccount, LinkedAccountEvent>;
  totalProcessedTransactions: number;
}

const initialState: LinkedAccountState = {
  isLoading: false,
  isSyncComplete: false,
  linkedAccounts: null,
  linkedAccountEvents: null,
  newEventsWhereProcessed: false,
  linkedAccountsWithEventMap:  new Map<LinkedAccount, LinkedAccountEvent>(),
  totalProcessedTransactions: 0, 
};

const linkedAccountReducerInternal = createReducer(
  initialState,
  on(LinkedAccountActions.ResetStore, () => ({
    ...initialState,
  })),

  on(LinkedAccountActions.IsLoading, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),

  on(LinkedAccountActions.SaveLinkedAccounts, (state, { payload }) => ({
    ...state,
    linkedAccounts: payload,
  })),

  on(LinkedAccountActions.SaveLinkedAccountsEvents, (state, { payload }) => ({
    ...state,
    linkedAccountEvents: payload,
  })),

  on(LinkedAccountActions.SetNewEventsWhereProcessed, (state, { newEventsWhereProcessed }) => ({
    ...state,
    newEventsWhereProcessed,
  })),

  on(LinkedAccountActions.SetSynchronizationComplete, (state, { isSyncComplete }) => ({
    ...state,
    isSyncComplete,
  })),

  on(LinkedAccountActions.SaveLinkedAccountsEventMap, (state, { map }) => ({
      ...state,
      linkedAccountsWithEventMap: map,
  })),

  on(LinkedAccountActions.SaveTotalProcessedTransactions, (state, { totalProcessedTransactions }) => ({
    ...state,
    totalProcessedTransactions,
})),

);

export function linkedAccountReducer(state: LinkedAccountState | undefined, action: any) {
  return linkedAccountReducerInternal(state, action);
}
