import { Routes } from '@angular/router';

import { authGuard } from './auth/auth.guard';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { OnboardingComponent } from './modules/onboarding/onboarding.component';
import { RedirectComponent } from '@shared/components/redirect/redirect.component';
import { homeGuard } from './modules/home/home.guard';
import { DownloadComponent } from '@shared/components/download/download.component';

export const AppRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },

  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },

      {
        path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [authGuard],
        loadChildren: () =>
          import('./modules/onboarding/onboarding.module').then(
            (m) => m.OnboardingModule
          ),
      },

      {
        path: 'upload',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./modules/home/merchant-uploads/merchant-uploads.module').then((m) => m.MerchantUploadsModule),
      },

      {
        path: 'cooperate-tax',
        loadChildren: () =>
          import('./modules/cooperate-tax/cooperate-tax.module').then((m) => m.CooperateTaxModule),
      },

      {
        path: 'redirect',
        canActivate: [authGuard, homeGuard],
        component: RedirectComponent,
        data: { title: 'Redirect'}
      },

      {
        path: 'download/:config',
        canActivate: [authGuard],
        component: DownloadComponent,
        data: { title: 'Download'}
      },
    ],
  },

  {
    path: '**',
    redirectTo: '/404',
  },
];
