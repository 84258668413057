import { catchError, Observable, of, tap } from 'rxjs';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, Router, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { ToastrModule } from 'ngx-toastr';
import { Amplify } from 'aws-amplify';

import { AppConfig } from './app.config';
import { AppComponent } from './app.component';
import { AppRoutes } from './app-routing.module';
import { AuthService } from './auth/auth.service';
import { SharedModule } from '@shared/shared.module';
import { LoggedInGuard } from '@app/auth/logged-in.guard';
import { AuthInterceptor } from './auth/auth-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorHandler } from '@shared/services/error-handler/http-error-handler.service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as fromApp from '@app/@store/app/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { LinkedAccountEffects } from './@store/linked-account/linked-account.effects';
import { SalesTaxConfigService } from '@services/sales-tax-config/sales-tax-config.service';

Amplify.configure({
  Auth: {
    Cognito: {
      // mandatorySignIn: true,
      // region: AppConfig.REGION,
      userPoolId: AppConfig.USER_POOL_ID,
      userPoolClientId: AppConfig.USER_POOL_WEB_CLIENT_ID,
      // authenticationFlowType: AppConfig.AUTHENTICATION_FLOW_TYPE,
      loginWith: {
        email: true,
      }
    }
  }
});

export const initializeApp = (salesTaxConfigService: SalesTaxConfigService, router: Router, authService: AuthService): (() => Observable<any>) => {
  return (): Observable<any> => {
    if (!authService.isAuthenticated()) {
      return of();
    }

    if (!salesTaxConfigService.getConfig()) {
      return salesTaxConfigService.fetchSalesTaxConfig().pipe(
        catchError((error) => {
          return of();
        })
      );
    }

    return of();
  };
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    RouterModule.forRoot(
      AppRoutes,
      { preloadingStrategy: PreloadAllModules, bindToComponentInputs: true }
    ),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      toastClass: 'ngx-toastr mt-2',
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true,
      enableHtml: true
    }), // Toastr Module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(fromApp.appReducer, {
      metaReducers: fromApp.metaReducers
    }),
    EffectsModule.forRoot([
      /**
       * INSTRUCTIONS: Arrange each effect in alphabetical order
       */
      
      LinkedAccountEffects,
    ]),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    AuthService,
    LoggedInGuard,
    HttpErrorHandler,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [SalesTaxConfigService, Router, AuthService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
