import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { UtilityService } from './utils/utility.service';
import { AuthService } from './auth/auth.service';
import { AnalyticsManagerService } from './utils/analytics-manager/analytics-manager.service';
import { EventTypes } from '@shared/meta-data/analytics-manager.meta';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent{
  authService: AuthService = inject(AuthService);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    public utilityService: UtilityService,
    private analyticsManagerService: AnalyticsManagerService
  ) {
    this.initEventManager();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event) => {
        this.titleService.setTitle(event.title + ' • AtomicTax');
        this.utilityService.pageInfo.next(event);
        this.analyticsManagerService.sendEvent(EventTypes.PAGE_VIEW, { url: event.title, timestamp: new Date().toISOString() })
      });
  }

  initEventManager() {
    this.analyticsManagerService.init();
  }

}
