import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as AppActions from './app.actions';
import * as fromLinkedAccount from '../linked-account/linked-account.reducer';

/**
 * INSTRUCTIONS: Arrange each store in alphabetical order:
 */

export interface AppState {
  linkedAccount: fromLinkedAccount.LinkedAccountState
}

export const appReducer: ActionReducerMap<AppState> = {
  linkedAccount: fromLinkedAccount.linkedAccountReducer
};

export const resetStoreMetaReducer = <State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> =>  (state, action) => {
    if (action !== null && action.type === AppActions.ResetAllStores.type) {
        state = {} as State;
    }
    return reducer(state, action);
};

export const metaReducers: MetaReducer<any>[] = [resetStoreMetaReducer];

