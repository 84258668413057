import { Injectable, inject } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as LinkedAccountActions from './linked-account.actions';
import * as LinkedAccountSelectors from './linked-account.selectors';
import { map, of, exhaustMap, catchError, withLatestFrom, switchMap } from 'rxjs';
import * as fromApp from '../app/app.reducer';
import { Store } from '@ngrx/store';
import { LinkedAccountsService } from '@services/linked-accounts/linked-accounts.service';
import { LinkedAccount, LinkedAccountEvent, LinkedAccountEventStatus } from '@shared/meta-data';

@Injectable()
export class LinkedAccountEffects {
  private readonly actions$: Actions = inject(Actions);
  private readonly linkedAccountService: LinkedAccountsService = inject(
    LinkedAccountsService
  );

  constructor(private store: Store<fromApp.AppState>) {}

  getLinkedAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountActions.GetLinkedAccounts),
      exhaustMap(() => {
        this.store.dispatch(
          LinkedAccountActions.IsLoading({ isLoading: false })
        );

        return this.linkedAccountService.fetchLinkedAccounts().pipe(
          map((event) => {
            return LinkedAccountActions.SaveLinkedAccounts({
              payload: event,
            });
          }),
          catchError(() => {
            return of({
              type: '[Linked Account] Failed to get linked accounts',
            });
          })
        );
      })
    )
  );

  getLinkedAccountsEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountActions.GetLinkedAccountsEvents),
      withLatestFrom(this.store.select(state => state.linkedAccount.linkedAccountEvents)),
      switchMap(([_, currentEvents]) => {
        this.store.dispatch(LinkedAccountActions.IsLoading({ isLoading: false }));
        
        return this.linkedAccountService.fetchLinkedAccountsEvents().pipe(
          map((newEvents: LinkedAccountEvent[]) => {
            if(currentEvents) {
              const hasNewEvents = newEvents.length >  currentEvents?.length;
              this.store.dispatch(LinkedAccountActions.SetNewEventsWhereProcessed({ newEventsWhereProcessed: hasNewEvents }));
            }

            return LinkedAccountActions.SaveLinkedAccountsEvents({ payload: newEvents })
          }),
          catchError(() => {
            return of({
              type: '[Linked Account] Failed to get linked accounts Events',
            });
          })
        );
      })
    )
  );

  combineAccountsAndEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountActions.SaveLinkedAccounts, LinkedAccountActions.SaveLinkedAccountsEvents),
      withLatestFrom(
        this.store.select(LinkedAccountSelectors.getLinkedAccounts),
        this.store.select(LinkedAccountSelectors.getLinkedAccountsEvents)
      ),
      map(([_, accounts, events]) => {
        if (accounts && events) {
          return (LinkedAccountActions.CombineLinkedAccountsAndEvents({ accounts, events }));
        }
        return ({
          type: '[Linked Account] account or event is missing',
        });
      })
    )
  );

  createAccountEventMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LinkedAccountActions.CombineLinkedAccountsAndEvents),
      map(({ accounts, events }) => {
        const accountEventMap:Map<LinkedAccount, LinkedAccountEvent> = new Map();
        let anyProcessingEvent: boolean = false;
        let incompletePullCount: number = 0;
        
        accounts.forEach(account => {
          const latestEvent = events
            .filter(event => event.token_id === account._token_id)
            .sort((a, b) => new Date(b.occured_at).getTime() - new Date(a.occured_at).getTime())[0];

          if (latestEvent && latestEvent.status !== LinkedAccountEventStatus.COMPLETED) {
            anyProcessingEvent = true;
            incompletePullCount += +account._pull_count || 0;
          }
          
          if(latestEvent) {
            accountEventMap.set(account, latestEvent);
          }
        });        
        
        this.store.dispatch(LinkedAccountActions.SetSynchronizationComplete({ isSyncComplete: !anyProcessingEvent }));
        this.store.dispatch(LinkedAccountActions.SaveTotalProcessedTransactions({ totalProcessedTransactions: incompletePullCount }));

        return LinkedAccountActions.SaveLinkedAccountsEventMap({ map: accountEventMap });
      })
    )
  );
}