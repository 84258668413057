import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { inject } from '@angular/core';

import { AuthService } from './auth.service';


export const authGuard: CanActivateFn = (
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree => {
  const authService: AuthService = inject(AuthService);

  if (!authService.isAuthenticated()) {
    let authenticateThrough: 'register' | 'login';
    if (state.url?.startsWith('/redirect') || state.url?.includes('new_user=true')) {
      authenticateThrough = 'register'
    }

    // We should only set redirect url if the user is not authenticated
    authService.logoutUser(state.url, authenticateThrough);

    return false;
  }

  return true;
};
